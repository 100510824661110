import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import moment from "moment";
import LogoutModal from "../../logoutModal/LogoutModal";

const UpdateClient = ({ isOpen, onClose, data }) => {
  const token = useAuth().token;
  const role = useAuth().role;
  const userId = useAuth().userId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState(data[0].name);
  const [loading, setLoading] = useState(false);
  const [intakeDate, setIntakeDate] = useState("");
  const [programCompletionDate, setProgramCompletionDate] = useState("");
  const [email, setEmail] = useState(data[0].Email);
  const [phone, setPhone] = useState(data[0].Phone);
  const [status, setStatus] = useState(data[0].ActiveStatus);
  const [notes, setNotes] = useState(data[0].notes);
  const [isExpire, setIsExpire] = useState(false);

  const handleAddClick = () => {
    if (email !== "" && !validateEmail(email)) {
      toast.warn("Email is Invalid!");
      return;
    }

    setLoading(true);
    const payload = {
      ClientID: data[0].ClientID,
      name: name,
      intakeDate: intakeDate,
      programCompletionDate: programCompletionDate,
      activeStatus: status,
      email: email,
      phone: phone,
      updatedBy: userId,
      notes: notes,
    };

    fetch(`${base_url}/client/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Client Updated Successful!");
        onClose();
      })
      .catch((error) => {
        console.error("Error Updating Client:", error);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const handleDelete = () => {
    const apiUrl = `${base_url}/client/delete/${data[0].ClientID}`;

    if (
      window.confirm("Are you sure you want to delete this client?") == false
    ) {
      toast.info("client deletion cancelled");
      return;
    }

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (response.status === 404) {
          toast.warn(
            "appointment is already present for this client. Please delete that appointment first"
          );
          return;
        } else if (response.status === 200) {
          toast.success("Client deleted successfully");
          // onClose();
          return;
        } else {
          setIsExpire(true);
          // console.log(`setexpire running`);
          return;
        }
      })
      .catch((error) => {
        toast.error("Error deleting client");
        console.error("Error sending data:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  useEffect(() => {
    // console.log(`runnig 1`, data);

    let startDate = moment.utc(data[0].IntakeDate).format("YYYY-MM-DD");
    setIntakeDate(startDate);

    let endDate = moment
      .utc(data[0].ProgramCompletionDate)

      .format("YYYY-MM-DD");
    setProgramCompletionDate(endDate);
    // console.log(`runnig`);
  }, []);

  return (
    <>
      <Modal show={isOpen} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Client Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isExpire && (
            <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
          )}
          <div className="">
            <div className="d-flex align-items-center mb-3">
              <label htmlFor="name" className="w-25">
                Name
              </label>
              {role === "Super Admin" ? (
                <input
                  type="text"
                  id="name"
                  className="form-control "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  // disabled
                />
              ) : (
                <input
                  type="text"
                  id="name"
                  className="form-control "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  disabled
                />
              )}
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="start" className="w-25">
                Start Date
              </label>
              {role === "Super Admin" ? (
                <input
                  type="date"
                  id="start"
                  className="form-control "
                  value={intakeDate}
                  onChange={(e) => setIntakeDate(e.target.value)}
                  placeholder="Intake Date"
                  // disabled
                />
              ) : (
                <input
                  type="date"
                  id="start"
                  className="form-control "
                  value={intakeDate}
                  onChange={(e) => setIntakeDate(e.target.value)}
                  placeholder="Intake Date"
                  disabled
                />
              )}
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="end" className="w-25">
                End Date
              </label>
              {role === "Super Admin" || role === "Supervisor" ? (
                <input
                  type="date"
                  id="end"
                  className="form-control"
                  value={programCompletionDate}
                  onChange={(e) => setProgramCompletionDate(e.target.value)}
                  placeholder="Program Completion Date"
                  // disabled
                />
              ) : (
                <input
                  type="date"
                  id="end"
                  className="form-control"
                  value={programCompletionDate}
                  onChange={(e) => setProgramCompletionDate(e.target.value)}
                  placeholder="Program Completion Date"
                  disabled
                />
              )}
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="email" className="w-25">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email (Optional)"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="phone" className="w-25">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                maxLength={10}
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setPhone(value);
                  } else {
                    toast.warn("Enter Numeric Value Only!");
                  }
                }}
                placeholder="Phone (Optional)"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="dropdown" className="w-25">
                Status
              </label>
              <select
                id="dropdown"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                className="form-select"
                aria-label="Default select example"
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="notes" className="w-25">
                Note
              </label>
              <input
                type="textarea"
                id="notes"
                className="form-control "
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Notes (Optional)"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          {role === "Super Admin" && (
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button variant="primary" onClick={handleAddClick}>
            Update Client
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateClient;
