import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import moment from "moment";
import LogoutModal from "../../logoutModal/LogoutModal";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const EventModal = ({
  handleOpenEvent,
  event,
  updateAttendenceAPI,
  isOpen,
}) => {
  const [isExpire, setIsExpire] = useState(false);
  const [attendenceStatus, setAttendenceStatus] = useState(
    event.attendance === 1
      ? event.ExcusedAbsence === null
        ? "Marked as Present"
        : "Excused Absence"
      : event.attendance === 0
      ? "Marked as Absent"
      : event.attendance === 2
      ? "Cancelled"
      : new Date(event.date) <= new Date()
      ? "Unchecked"
      : "Future Booking"
  );
  const base_url = process.env.REACT_APP_BASE_URL;
  const location = useLocation();

  const token = useAuth().token;
  const userId = useAuth().userId;
  const role = useAuth().role;
  const [excusedAbsenceInput, setExcusedAbsenceInput] = useState(false);
  const [remark, setRemark] = useState("");

  const handleAbsent = () => {
    const apiUrl = `${base_url}/appointment/attendance`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        AppointmentID: event.id,
        attendance: 0,
        remark: null,
        updatedBy: userId,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then((data) => {
        setAttendenceStatus("Absent");
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        toast.success(`Attendance updated Successfully!`);
        updateAttendenceAPI(event.id, 0, null);
      });
  };

  const handlePresent = () => {
    const apiUrl = `${base_url}/appointment/attendance`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        AppointmentID: event.id,
        attendance: 1,
        remark: null,
        updatedBy: userId,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then(() => {
        setAttendenceStatus("Present");
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        toast.success(`Attendance updated Successfully!`);
        updateAttendenceAPI(event.id, 1, null);
      });
  };

  const handleCancel = () => {
    const apiUrl = `${base_url}/appointment/attendance`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        AppointmentID: event.id,
        attendance: 2,
        remark: null,
        updatedBy: userId,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then(() => {
        setAttendenceStatus("Cancelled");
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        toast.success(`Attendance updated Successfully!`);
        updateAttendenceAPI(event.id, 2, null);
      });
  };

  const handleExcusedAbsence = () => {
    setExcusedAbsenceInput(true);
  };

  const handleRemarkSubmit = () => {
    if (remark.length == 0) {
      alert("Please enter a valid reason");
      return;
    }
    const apiUrl = `${base_url}/appointment/attendance`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        AppointmentID: event.id,
        attendance: 1,
        remark: remark,
        updatedBy: userId,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then((data) => {
        setAttendenceStatus("Present");
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        toast.success(`Attendance updated Successfully!`);
        updateAttendenceAPI(event.id, 1, remark);
      });
  };

  const handleDelete = () => {
    if (
      window.confirm("Are you sure you want to delete this appointment?") ==
      false
    ) {
      toast.info("appointment deletion cancelled");
      return;
    }

    const apiUrl = `${base_url}/appointment/delete/${event.id}`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Appointment deleted successfully");
        return response.json();
      })
      .catch((error) => {
        toast.error("Error deleting appointment");
        console.error("Error sending data:", error);
      })
      .finally(() => {
        updateAttendenceAPI(event.id, -1, null);
        handleOpenEvent();
      });
  };

  useEffect(() => {
    setAttendenceStatus(
      event.attendance === 1
        ? event.ExcusedAbsence === null
          ? "Marked as Present"
          : "Excused Absence"
        : event.attendance === 0
        ? "Marked as Absent"
        : event.attendance === 2
        ? "Cancelled"
        : new Date(event.date) <= new Date()
        ? "Unchecked"
        : "Future Booking"
    );
    setExcusedAbsenceInput(false);
    setRemark(event.excused_absence_comment);
  }, [isOpen]);

  return (
    <>
      <Modal className="modal-lg" show={isOpen} onHide={handleOpenEvent}>
        <Modal.Header closeButton>
          <Modal.Title>Appointment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isExpire && (
            <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
          )}
          <div className="mt-4">
            <div className="card mb-4">
              <div className="container pt-3">
                <table border="1" className="table table-striped table-hover">
                  <tbody className="">
                    <tr className="py-2">
                      <th scope="row">Appointment ID:</th>
                      <td>{event.id}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Client Name:</th>
                      <td>{event.client_name}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Department Name:</th>
                      <td>{event.name}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Sub Department Name:</th>
                      <td>{event.sub_department_name}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Attendance:</th>
                      <td
                        className={`fw-bold ${
                          event.attendance === 1
                            ? "text-success"
                            : event.attendance === 0
                            ? "text-danger"
                            : "text-dark"
                        } `}
                      >
                        {attendenceStatus}
                      </td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Date:</th>
                      <td>{moment.utc(event.date).format("MMM Do YYYY")}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Start Time:</th>
                      <td>
                        {moment(event.start_time, "HH:mm:ss").format("hh:mm A")}
                      </td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">End Time:</th>
                      <td>
                        {moment(event.end_time, "HH:mm:ss").format("hh:mm A")}
                      </td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Note:</th>
                      <td>{event.note}</td>
                    </tr>
                    {role === "Super Admin" && (
                      <>
                        <tr className="py-2">
                          <th scope="row">Updated By:</th>
                          <td>{event.user_name}</td>
                        </tr>
                        <tr className="py-2">
                          <th scope="row">Updated Time:</th>
                          <td>
                            {new Date(event.updated_at).toLocaleString(
                              "en-US",
                              {
                                timeZone: "America/New_York",
                              }
                            )}{" "}
                            ET
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 ">
            <div className="d-flex justify-content-around">
              <Button variant="secondary" onClick={handleOpenEvent}>
                Close
              </Button>
              {location.pathname !== "/reports" && (
                <>
                  {role !== "Viewer" && (
                    <>
                      <button className="btn btn-danger" onClick={handleAbsent}>
                        Absent
                      </button>
                      <button
                        className="btn btn-success"
                        onClick={handlePresent}
                      >
                        Present
                      </button>
                      <button
                        className="btn btn-warning"
                        onClick={handleExcusedAbsence}
                      >
                        Excused Absence
                      </button>
                      <button className="btn btn-info" onClick={handleCancel}>
                        Cancel
                      </button>
                      {role === "Super Admin" && (
                        <button
                          className="btn btn-danger"
                          onClick={handleDelete}
                        >
                          Delete
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            {excusedAbsenceInput && (
              <div className="d-flex gap-4 mt-3">
                <input
                  type="text"
                  placeholder="Enter Reason"
                  className="form-control w-75"
                  name={remark}
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
                <button
                  className="btn btn-primary"
                  onClick={handleRemarkSubmit}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventModal;
