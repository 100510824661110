import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import { UserRoute } from "./routes/UserRoute";
import { SuperAdminControl } from "./routes/SuperAdminControl";
import Login from "./pages/Login";
import CalendarPage from "./pages/CalendarPage";
import DashboardPage from "./pages/DashboardPage";
import AppointmentPage from "./pages/AppointmentPage";
import ReportsPage from "./pages/ReportsPage";
import DepartmentPage from "./pages/DepartmentPage";
import ClientsPage from "./pages/ClientsPage";
import UserPage from "./pages/UserPage";
import SubDepartmentPage from "./pages/SubDepartmentPage";
import ShilohAlignmentPage from "./pages/ShilohAlignmentPage";
import Navbar from "./components/Navbar";
import { AdminControl } from "./routes/AdminControl";
import ProgramPage from "./pages/ProgramPage";
import ClientComplienceReportPage from "./pages/ClientComplienceReport";
import ClientComplienceTablePage from "./pages/ClientComplienceTablePage";
import ClientComplianceTablePage from "./pages/ClientComplienceTablePage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <UserRoute>
                <Login />
              </UserRoute>
            }
          ></Route>
          <Route
            exact
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Navbar />
                <DashboardPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/program"
            element={
              <ProtectedRoute>
                <AdminControl>
                  <Navbar />
                  <ProgramPage />
                </AdminControl>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/calendar"
            element={
              <ProtectedRoute>
                <Navbar />
                <CalendarPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/appointment"
            element={
              <ProtectedRoute>
                <AdminControl>
                  <Navbar />
                  <AppointmentPage />
                </AdminControl>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/reports"
            element={
              <ProtectedRoute>
                <AdminControl>
                  <Navbar />
                  <ReportsPage />
                </AdminControl>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/department"
            element={
              <ProtectedRoute>
                <SuperAdminControl>
                  <Navbar />
                  <DepartmentPage />
                </SuperAdminControl>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/subdepartment"
            element={
              <ProtectedRoute>
                <SuperAdminControl>
                  <Navbar />
                  <SubDepartmentPage />
                </SuperAdminControl>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/client"
            element={
              <ProtectedRoute>
                <AdminControl>
                  <Navbar />
                  <ClientsPage />
                </AdminControl>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            exact
            path="/compliance-report"
            element={
              <ProtectedRoute>
                <SuperAdminControl>
                  <Navbar />
                  <ClientComplienceReportPage />
                </SuperAdminControl>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/shilohAlignment"
            element={
              <ProtectedRoute>
                <AdminControl>
                  <Navbar />
                  <ShilohAlignmentPage />
                </AdminControl>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/user"
            element={
              <ProtectedRoute>
                <SuperAdminControl>
                  <Navbar />
                  <UserPage />
                </SuperAdminControl>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            exact
            path="/Compliance"
            element={
              <ProtectedRoute>
                <SuperAdminControl>
                  <Navbar />
                  <ClientComplianceTablePage />
                </SuperAdminControl>
              </ProtectedRoute>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
