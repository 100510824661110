import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/useAuth";
import LogoutModal from "../../logoutModal/LogoutModal";
import moment from "moment";
import CheckBookingModel from "./CheckBookingModel";

const AddAppointment = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const userId = useAuth().userId;
  const role = useAuth().role;
  const [isExpire, setIsExpire] = useState(false);
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState("07:00");
  const [endTime, setEndTime] = useState("07:00");
  const [client, setClient] = useState("");
  const [note, setNote] = useState("");
  const [clients, setClients] = useState([]);
  const [department, setDepartment] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [Departments, setDepartments] = useState([]);
  const [Subdepartments, setSubdepartments] = useState([]);
  const [slot, setSlot] = useState("00");
  const [isRecurringAppointment, setIsRecurringAppointment] = useState(false);
  const [completionDate, setCompletionDate] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  let resultDays = [];
  const [bookableDates, setBookableDates] = useState([]);
  const [notBookableDates, setNotBookableDates] = useState([]);
  const [isAppointmentBookable, setIsAppointmentBookable] = useState(false);
  const [availabilityShowAlert, setAvailabilityShowAlert] = useState(false);
  const [bookedShowAlert, setBookedShowAlert] = useState(false);
  const programId = useAuth().programId;
  const [programs, setPrograms] = useState([]);
  const [programName, setProgramName] = useState("");
  const [isUnBopokedApp, setIsUnchekedApp] = useState(false);
  const [unBookableDates, setUnBookableDates] = useState([]);
  const [isOpenEvent, setIsOpenEvent] = useState(false);
  const [bookedAppointments, setBookedAppointments] = useState([]);

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  const getDepartments = async () => {
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["name"]}`);
        }
        resolve(temp);
      });
      setDepartments(temp);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const getSubdepartments = async (val) => {
    try {
      const response = await fetch(`${base_url}/subdepartment/deptId/${val}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log(`temp : `, data);
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(
            `${data[i]["id"]}:${data[i]["name"]}:${data[i]["slot_duration"]}`
          );
        }
        resolve(temp);
      });
      setSubdepartments(temp);
    } catch (error) {
      console.error("Error fetching subdepartments:", error);
    }
  };

  const getClients = async () => {
    try {
      const payload = {
        programId: programId,
      };

      const response = await fetch(`${base_url}/client/get/active`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        setIsExpire(true);
        return;
      }

      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(
            `${data[i]["id"]};${data[i]["client_name"]};${data[i]["program_completion_date"]}`
          );
        }
        resolve(temp);
      });

      setClients(temp);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleDateChange = (selectedDate) => {
    setAvailabilityShowAlert(false);
    setBookedShowAlert(false);
    setIsUnchekedApp(false);
    setIsAppointmentBookable(false);
    if (client == "") {
      toast.warn("Please select Client First!");
      return;
    }

    if (new Date(completionDate) <= new Date(selectedDate)) {
      toast.warn(
        "Appointment booking is not permitted after the completion Date"
      );
      setDate(null);
      return;
    }
    setDate(selectedDate);
  };

  const handleEndTimeChange = (selectedTime) => {
    setAvailabilityShowAlert(false);
    setBookedShowAlert(false);
    setIsUnchekedApp(false);
    setIsAppointmentBookable(false);
    setEndTime(selectedTime.target.value);
  };

  const handleAddNote = (e) => {
    setAvailabilityShowAlert(false);
    setBookedShowAlert(false);
    setIsUnchekedApp(false);
    setIsAppointmentBookable(false);
    setNote(e.target.value);
  };

  const handleTypeaheadChange = (selected) => {
    setClient(selected);
    setAvailabilityShowAlert(false);
    setBookedShowAlert(false);
    setIsUnchekedApp(false);
    setIsAppointmentBookable(false);
    if (selected.length === 0) {
      setCompletionDate(null);
      setDate(null);
      return;
    }
    const data = clients.find((ele) => ele.split(";")[1] == selected);
    const compDate = data.split(";")[2];
    const newDate = new Date(compDate);
    let today;

    if (date == null) {
      today = new Date(Date.now());
    } else {
      today = new Date(date);
    }

    if (newDate.getTime() <= today.getTime()) {
      toast.warn("Client is not in prescribe date range");
    } else {
      toast.success("Client is in prescribe date range");
    }
    const newCompDate = moment.utc(compDate).format("YYYY-MM-DD");
    setCompletionDate(newCompDate);
  };

  function convertTimeToDate(timeString) {
    const date = new Date(timeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function getDayAppointments(clientID, depId, subDepId) {
    const apiUrl = `${base_url}/appointment/day`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        date: convertTimeToDate(date),
        programId: programId,
        clientID: clientID,
        depId: depId,
        subDepId: subDepId,
      }),
    };
    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then((data) => {
        // console.log(`data: `, data);
        setBookedAppointments(data);
        // setAppointments(data);
      });
  }

  async function checkBookable(dataToSend) {
    // console.log(`dataToSend: `, dataToSend);
    setIsOpenEvent(true);
    await getDayAppointments(
      dataToSend.clientID,
      dataToSend.departmentID,
      dataToSend.subDepartmentID
    );
    const apiUrl = `${base_url}/appointment/bookable`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dataToSend),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then((data) => {
        let temp = [];
        let dates = [];
        let temp2 = [];

        let n = data.length;
        for (let i = 0; i < n; i++) {
          if (data[i].toBook == true) {
            temp.push(data[i]);
            dates.push(data[i].currDate);
          } else {
            temp2.push(data[i]);
          }
        }

        if (dates.length == 0) {
          setIsAppointmentBookable(false);
        } else {
          setIsAppointmentBookable(true);
        }

        if (temp2.length != 0) {
          setIsAppointmentBookable(false);
        } else {
          setIsAppointmentBookable(true);
        }

        setBookableDates(dates);
        setNotBookableDates(temp2);
        setAvailabilityShowAlert(true);
        setBookedShowAlert(false);
        setIsUnchekedApp(false);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {});
  }

  function AddBooking(dataToSend) {
    const apiUrl = `${base_url}/appointment/add`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dataToSend),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data.message.length);
        // newly added
        if (data.message.length > 0) {
          setIsUnchekedApp(true);
          setUnBookableDates(data.message);
        } else {
          setBookedShowAlert(true);
          setAvailabilityShowAlert(false);
          setIsAppointmentBookable(false);
        }
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {});
  }

  const handleStartTimeChange = (selectedTime) => {
    setAvailabilityShowAlert(false);
    setIsUnchekedApp(false);
    setBookedShowAlert(false);
    setIsAppointmentBookable(false);
    setStartTime(selectedTime.target.value);
    setEndTime(addTimeSlot(selectedTime.target.value, slot));
  };

  function setEndTimeBySlot(event) {
    const slotArray = Subdepartments.find(
      (subDepartment) => subDepartment.split(":")[0] == event
    );
    const data = slotArray.split(":")[2];
    setSlot(data);
    setEndTime(addTimeSlot(startTime, data));
  }

  function addTimeSlot(timeString, minutesToAdd) {
    const [hours, minutes] = timeString.split(":").map(Number);

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    const dateTime = date.getTime() + minutesToAdd * 60000;

    const newDateTime = new Date(dateTime);

    const newHours = String(newDateTime.getHours()).padStart(2, "0");
    const newMinutes = String(newDateTime.getMinutes()).padStart(2, "0");

    return `${newHours}:${newMinutes}`;
  }

  function handleAttendanceChange() {
    setAvailabilityShowAlert(false);
    setBookedShowAlert(false);
    setIsUnchekedApp(false);
    setIsAppointmentBookable(false);
    setIsRecurringAppointment(!isRecurringAppointment);
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setSelectedDays([...selectedDays, name]);
    } else {
      setSelectedDays(selectedDays.filter((day) => day !== name));
    }
    setAvailabilityShowAlert(false);
    setBookedShowAlert(false);
    setIsUnchekedApp(false);
    setIsAppointmentBookable(false);
  };

  const getDatesForDays = () => {
    const startDate = new Date(date);
    const endDate = new Date(completionDate);
    const dayMap = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
    };

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const currentDay = currentDate.getDay();
      if (
        selectedDays.includes(
          Object.keys(dayMap).find((key) => dayMap[key] === currentDay)
        )
      ) {
        resultDays.push(convertTimeToDate(new Date(currentDate)));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  };

  const handleCheckAvailability = async () => {
    if (department.length == 0) {
      toast.warn("Please select Department First!");
      return;
    }

    if (subdepartment.length == 0) {
      toast.warn("Please select Sub-Department First!");
      return;
    }

    if (client.length == 0) {
      toast.warn("Please select Client First!");
      return;
    }

    if (date == null) {
      toast.warn("Please select Date First!");
      return;
    }

    if (isRecurringAppointment == true) {
      if (selectedDays.length == 0) {
        toast.warn("Please select Recurring Days First!");
        return;
      }
    }
    const newDate = date.toISOString().split("T")[0] + "T" + startTime;

    const diff =
      (new Date(new Date(newDate)).getTime() - new Date().getTime()) /
      (60 * 60 * 1000);

    if (role == "Admin") {
      if (diff < 12) {
        toast.warn(
          "Appointment booking is not permitted within the next 12 hours. Please select a date and time that is at least 12 hours from now."
        );
        return;
      }
    }

    var clientID = await new Promise((resolve, reject) => {
      clients.forEach((elem) => {
        if (elem.includes(client[0])) {
          resolve(elem.split(";")[0]);
        }
      });
      resolve(-1);
    });

    if (isRecurringAppointment == false) {
      checkBookable({
        departmentID: department,
        subDepartmentID: subdepartment,
        clientID: Number(clientID),
        startTime: startTime,
        endTime: endTime,
        date: [convertTimeToDate(date)],
        isRecurring: false,
      });
    } else {
      await getDatesForDays();
      checkBookable({
        departmentID: department,
        subDepartmentID: subdepartment,
        clientID: Number(clientID),
        startTime: startTime,
        endTime: endTime,
        date: resultDays,
      });
    }
  };

  const handleConfirmBooking = async () => {
    if (department.length == 0) {
      toast.warn("Please select Department First!");
      return;
    }

    if (subdepartment.length == 0) {
      toast.warn("Please select Sub-Department First!");
      return;
    }

    if (client.length == 0) {
      toast.warn("Please select Client First!");
      return;
    }

    if (date == null) {
      toast.warn("Please select Date First!");
      return;
    }

    if (isRecurringAppointment == true) {
      if (selectedDays.length == 0) {
        toast.warn("Please select Recurring Days First!");
        return;
      }
    }

    if (availabilityShowAlert == false && role !== "Super Admin") {
      toast.warn("Please check availability first!");
      return;
    }
    if (isAppointmentBookable == false && role !== "Super Admin") {
      toast.warn("Slots are not available for booking!");
      return;
    }

    var clientID = await new Promise((resolve, reject) => {
      clients.forEach((elem) => {
        if (elem.includes(client[0])) {
          resolve(elem.split(";")[0]);
        }
      });
      resolve(-1);
    });

    if (isRecurringAppointment == false) {
      AddBooking({
        departmentID: department,
        subDepartmentID: subdepartment,
        clientID: Number(clientID),
        startTime: startTime,
        endTime: endTime,
        date: [convertTimeToDate(date)],
        updatedBy: userId,
        programId: programId,
        note: note,
      });
    } else {
      await getDatesForDays();
      if (role === "Super Admin") {
        AddBooking({
          departmentID: department,
          subDepartmentID: subdepartment,
          clientID: Number(clientID),
          startTime: startTime,
          endTime: endTime,
          date: resultDays,
          updatedBy: userId,
          programId: programId,
          note: note,
        });
      } else {
        AddBooking({
          departmentID: department,
          subDepartmentID: subdepartment,
          clientID: Number(clientID),
          startTime: startTime,
          endTime: endTime,
          date: bookableDates,
          updatedBy: userId,
          programId: programId,
          note: note,
        });
      }
    }
  };

  const getPrograms = async () => {
    try {
      const response = await fetch(`${base_url}/program/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      setPrograms(data);
      const newprogram = data.find((program) => program.id == programId);
      setProgramName(newprogram.name);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleOpenEvent = () => {
    setIsOpenEvent(false);
  };

  useEffect(() => {
    getDepartments();
    getClients();
    getPrograms();
  }, []);

  return (
    <div className="">
      <h3 className="pt-4">Book an appointment</h3>
      <hr />
      <form>
        {/* program */}
        <div className="mb-3 row">
          <label htmlFor="program" className="col-sm-2 col-form-label">
            Program
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              id="program"
              className="form-control w-50"
              value={programName}
              // onChange={(e) => setIntakeDate(e.target.value)}
              placeholder="program"
              disabled
            />
          </div>
        </div>

        {/* department */}
        <div className="mb-3 row">
          <label htmlFor="department" className="col-sm-2 col-form-label">
            Department
          </label>
          <div className="col-sm-10">
            <select
              className="form-select w-50"
              id="department"
              aria-label="Default select example"
              defaultValue=""
              onChange={async (e) => {
                if (e.target.value !== "") {
                  await getSubdepartments(e.target.value);
                } else {
                  setSubdepartments([]);
                }
                setAvailabilityShowAlert(false);
                setBookedShowAlert(false);
                setIsUnchekedApp(false);
                setIsAppointmentBookable(false);
                setDepartment(e.target.value);
              }}
            >
              <option value="">Select a Department</option>
              {Departments.map((option, index) => (
                <option key={index} value={option.split(":")[0]}>
                  {option.split(":")[1]}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* subdepartment */}
        <div className="mb-3 row">
          <label htmlFor="subdepartment" className="col-sm-2 col-form-label">
            Sub Department
          </label>
          <div className="col-sm-10">
            <select
              className="form-select w-50"
              id="subdepartment"
              aria-label="Default select example"
              onChange={async (e) => {
                if (isNaN(e.target.value) === false) {
                  setEndTimeBySlot(e.target.value);
                } else {
                  setEndTime("01:00");
                }
                setAvailabilityShowAlert(false);
                setBookedShowAlert(false);
                setIsUnchekedApp(false);
                setIsAppointmentBookable(false);
                setSubdepartment(e.target.value);
              }}
            >
              <option selected>Select a Sub Department</option>
              {Subdepartments.map((option, index) => (
                <option key={index} value={option.split(":")[0]}>
                  {option.split(":")[1]}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* clients */}
        <div className="mb-3 row">
          <label htmlFor="client" className="col-sm-2 col-form-label">
            Client &nbsp;&nbsp;
          </label>

          <div className="col-sm-10">
            <Typeahead
              className="w-50"
              id="client"
              onChange={handleTypeaheadChange}
              options={clients.map((elem) => elem.split(";")[1])}
              selected={client}
              placeholder="Select a Client"
            />
          </div>
        </div>

        {/* Dates */}
        <div className="mb-3 row">
          <label htmlFor="datePicker" className="col-sm-2 col-form-label">
            Date
          </label>
          <div className="col-sm-10">
            <DatePicker
              id="datePicker"
              selected={date}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              className="form-control z-1"
              placeholderText="Select a Date"
              autoComplete="off"
            />
          </div>
        </div>

        {/* completion Date */}
        <div className="mb-3 row">
          <label
            htmlFor="completionDatePicker"
            className="col-sm-2 col-form-label"
          >
            Completion Date
          </label>
          <div className="col-sm-10">
            <DatePicker
              id="completionDatePicker"
              selected={completionDate}
              onChange={handleEndTimeChange}
              dateFormat="MM/dd/yyyy"
              className="form-control z-1"
              placeholderText="Select a Date"
              autoComplete="off"
              disabled
            />
          </div>
        </div>

        {/* Start time */}
        <div className="mb-3 row">
          <label htmlFor="starttime" className="col-sm-2 col-form-label">
            Start
          </label>
          <div className="col-sm-10">
            <input
              type="time"
              className="form-control w-50"
              value={startTime}
              onChange={(e) => handleStartTimeChange(e)}
            />
          </div>
        </div>

        {/* end Time */}
        <div className="mb-3 row">
          <label htmlFor="endtime" className="col-sm-2 col-form-label">
            End
          </label>
          <div className="col-sm-10">
            <input
              type="time"
              className="form-control w-50"
              value={endTime}
              onChange={handleEndTimeChange}
              disabled
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="note" className="col-sm-2 col-form-label">
            Note
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control w-50"
              value={note}
              onChange={handleAddNote}
            />
          </div>
        </div>

        {/* switch button */}
        <div className="form-check form-switch mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="recurring"
            onChange={handleAttendanceChange}
          />
          <label className="form-check-label" htmlFor="recurring">
            Recurring Appointments
          </label>
        </div>

        {isRecurringAppointment && (
          <div className="d-grid gap-2  p-2 ">
            {days.map((day) => (
              <div
                key={day}
                className="px-3 d-flex flex-row align-items-center "
              >
                <div style={{ width: "30px" }} className="">
                  <input
                    className="me-2"
                    type="checkbox"
                    role="switch"
                    id={day}
                    name={day}
                    autoComplete="off"
                    checked={selectedDays.includes(day)}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <label className="" htmlFor={day}>
                    {day}
                  </label>
                </div>

                <div style={{ width: "120px" }} className="me-5">
                  <input
                    type="time"
                    className="form-control"
                    value={startTime}
                    disabled
                  />
                </div>

                <div style={{ width: "120px" }}>
                  <input
                    type="time"
                    className="form-control"
                    value={endTime}
                    disabled
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {availabilityShowAlert &&
          (isAppointmentBookable ? (
            <div className="alert alert-success mt-3">
              <span className="fw-bolder">
                Slots are available for booking. Continue and Confirm Booking
              </span>{" "}
              <br />
            </div>
          ) : (
            <div className="alert alert-danger mt-3 ">
              <span className="fw-bolder">
                Booking confilcts as the client is already booked for these
                slots. Please clear details and select some other slots.
              </span>{" "}
              <br />
              {notBookableDates.map((days, index) => (
                <span key={index}>{days.currDate} , </span>
              ))}
            </div>
          ))}

        {bookedShowAlert && (
          <div className="alert alert-success mt-3">
            <span className="fw-bolder">Booked Successfully!</span>
          </div>
        )}
      </form>

      {role === "Super Admin" ? (
        <>
          <button className="btn btn-primary" onClick={handleConfirmBooking}>
            Confirm Booking
          </button>
        </>
      ) : (
        <>
          <div className="d-flex gap-3">
            <button
              className="btn btn-primary"
              onClick={handleCheckAvailability}
            >
              Check Availability
            </button>
            {isAppointmentBookable && (
              <button
                className="btn btn-primary"
                onClick={handleConfirmBooking}
              >
                Confirm Booking
              </button>
            )}
          </div>
        </>
      )}

      {isUnBopokedApp && (
        <div className="alert alert-danger mt-3">
          <span className="fw-bolder">
            Booking confilcts as the client is already booked for these slots.
          </span>{" "}
          <br />
          {unBookableDates.map((days, index) => (
            <span key={index}>{days} , </span>
          ))}
        </div>
      )}

      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      {/* <Button onClick={() => setIsOpenEvent(true)}>open</Button> */}
      {isOpenEvent && (
        <CheckBookingModel
          date={date}
          bookedAppointments={bookedAppointments}
          handleOpenEvent={handleOpenEvent}
          isOpen={isOpenEvent}
        />
      )}
    </div>
  );
};

export default AddAppointment;
