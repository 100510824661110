// import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/useAuth";
import { Navigate } from "react-router-dom";

// eslint-disable-next-line react/prop-types
export const AdminControl = ({ children }) => {
  const { role } = useAuth();
  if (role != "Super Admin" && role != "Admin" && role != "Supervisor") {
    return <Navigate to="/dashboard" />;
  }

  return children;
};
