import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import moment from "moment";
import LogoutModal from "../../logoutModal/LogoutModal";
import { faL } from "@fortawesome/free-solid-svg-icons";

const ChangePassword = ({ isOpen, onClose, data }) => {
  const token = useAuth().token;
  const role = useAuth().role;
  const adminId = useAuth().userId;
  const logout = useAuth().logout;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState(data[0].UserName);
  const [password, setPassword] = useState("");
  const [userRole, setUserRole] = useState(data[0].Role);
  const [loading, setLoading] = useState(false);
  const [isExpire, setIsExpire] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(
    data[0].DepartmentID
  );
  const [departments, setDepartments] = useState([]);
  const [isAdmin, setIsAdmin] = useState(
    data[0].Role == "Admin" ? true : false
  );

  // console.log(`data : `, data);

  const handleChangePassword = () => {
    // if (password === "") {
    //   toast.warn("Please enter new password!");
    //   return;
    // }
    let deptName;
    // console.log(`selectedDepartment : `, selectedDepartment);
    console.log(`selectedDepartment : `, selectedDepartment);
    if (selectedDepartment == null) {
      deptName = "";
    } else {
      const dept = departments.find(
        (department) => department.id == selectedDepartment
      );

      console.log(`dept : `, dept);
      deptName = dept.name;
    }

    setLoading(true);

    let payload;

    if (isAdmin === false) {
      payload = {
        userId: data[0].UserID,
        password: password,
        role: userRole,
        DepartmentID: null,
        DepartmentName: "",
        updatedBy: adminId,
      };
    } else {
      payload = {
        userId: data[0].UserID,
        password: password,
        role: userRole,
        DepartmentID: selectedDepartment,
        DepartmentName: deptName,
        updatedBy: adminId,
      };
    }

    console.log(`payload : `, payload);

    // const payload = {
    //   userId: data[0].UserID,
    //   password: password,
    //   role: role,
    //   DepartmentID: selectedDepartment,
    //   DepartmentName: deptName,
    //   updatedBy: adminId,
    // };

    fetch(`${base_url}/user/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Password Updated Successful!");
        onClose();
      })
      .catch((error) => {
        console.error("Error Updating password:", error);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const getDepartments = async () => {
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log(`department data : `, data);
      setDepartments(data);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, [isOpen]);

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            disabled
          />

          <input
            type="text"
            className="form-control mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New Password"
          />

          <select
            id="dropdown"
            value={userRole}
            onChange={(e) => {
              setUserRole(e.target.value);
              if (e.target.value == "Admin") {
                setIsAdmin(true);
              } else {
                setIsAdmin(false);
              }
            }}
            // disabled
            className="form-select mb-3"
            aria-label="Default select example"
          >
            <option value="">Select Role</option>
            <option value="Super Admin">Super Admin</option>
            <option value="Supervisor">Supervisor</option>
            <option value="Admin">Admin</option>
            <option value="Viewer">Viewer</option>
          </select>

          {isAdmin && (
            <select
              className="form-select  "
              value={selectedDepartment}
              onChange={(e) => {
                setSelectedDepartment(e.target.value);
              }}
            >
              <option value="">Select an Department</option>
              {departments.map((dept, index) => {
                return (
                  <>
                    <option key={index} value={dept.id}>
                      {dept.name}
                    </option>
                  </>
                );
              })}
            </select>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleChangePassword}>
          Update details
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePassword;
