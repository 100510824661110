import moment from "moment";
import React from "react";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";

const AppointmentModel = ({ handleOpenEvent, isOpen, data }) => {
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px", // Row text size
        color: "black", // Row text color
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };
  function convertTimeToDate(dateString) {
    const newDate = moment(dateString, "YYYY-MM-DD").format("MMM Do YYYY");
    return newDate;
  }

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      width: "70px",
    },
    {
      name: "Client",
      selector: (row) => row.client_name,
      width: "200px",
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
      width: "180px",
    },
    {
      name: "Sub Department	",
      selector: (row) => row.sub_department_name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Date-Time",
      selector: (row) =>
        `${convertTimeToDate(row.date)}, ${convertTimeToHHMM(
          row.start_time
        )} - ${convertTimeToHHMM(row.end_time)}`,
      width: "350px",
    },
    {
      name: "Attendance",
      selector: (row) =>
        row.attendance === 1
          ? row.excused_absence_comment === null
            ? "Present"
            : "Excused Absence"
          : row.attendance === 0
          ? "Absent"
          : row.attendance === 2
          ? "Cancelled"
          : new Date(row.date) <= new Date()
          ? "Unchecked"
          : "Future Booking",
      width: "150px",
    },
  ];
  return (
    <Modal className="modal-lg" show={isOpen} onHide={handleOpenEvent}>
      <Modal.Header closeButton>
        <Modal.Title>Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          customStyles={customStyles}
          highlightOnHover
          columns={columns}
          height="200px"
          data={data}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AppointmentModel;
